import Creatable from 'react-select/creatable'
import { useFormContext, Controller, FieldError } from 'react-hook-form'
import strings from 'l10n'
import { GroupBase, OptionsOrGroups } from 'react-select'
import styles from './CreatableFormField.module.scss'
import { isDefined } from 'tools/common'
import { useState } from 'react'

type CreatableFormFieldProps = {
  id?: string
  options: OptionsOrGroups<any, GroupBase<any>> | undefined
  disabled?: boolean
  name: string
  required?: boolean
  initialValue?: string | { label: string; value: string }
  label: string
  createOptionPosition?: 'first' | 'last'
  availableNewOption?: boolean
  maxLength?: { value: number; message: string }
  size?: string | number
  endAdornment?: React.ReactNode
  inputCreateLabel?: string
}

export const getCreatableValue = (value: string | { label: string; value: string }) => {
  if (typeof value === 'string') return value
  return value?.value
}

const CreatableFormField = (props: CreatableFormFieldProps) => {
  const {
    id,
    options,
    disabled,
    name,
    required,
    initialValue,
    label,
    createOptionPosition,
    availableNewOption = true,
    maxLength,
    size = '200px',
    endAdornment,
    inputCreateLabel = strings.CREATE,
  } = props
  const isRequired = required ? strings.FIELD_IS_REQUIRED : undefined
  const { control } = useFormContext()
  const [inputValue, setInputValue] = useState<string>('')

  const canCreateNewOption = (inputValue: string) => {
    if (!isDefined(inputValue) || inputValue === '') return false
    return availableNewOption
  }

  const getErrorMessage = (error: FieldError) => {
    if (error?.type === 'required') return isRequired
    if (error?.type === 'maxLength') return maxLength?.message
  }

  const getCreatableOptionValue = (value: string | { label: string; value: string } | undefined) => {
    if (!value) return null // If no value is selected, return null
    if (typeof value === 'object' && 'label' in value && 'value' in value) {
      return value // If it's already an object with label and value, return it as-is
    }
    return { label: value, value } // If it's a string, convert it into an object
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        validate: {
          maxLength: (option: any) => {
            const value = getCreatableValue(option)
            if (isDefined(value) && maxLength?.value) return value.length <= maxLength.value
            return true
          },
        },
      }}
      defaultValue={initialValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className={styles.creatableContainer} id={id}>
            <div className={styles.inputWithAdornment}>
              <Creatable
                name="account"
                formatCreateLabel={(inputValue) => `${inputCreateLabel} "${inputValue}"`}
                placeholder={`${label} ${required ? '*' : ''}`}
                options={options}
                required
                createOptionPosition={createOptionPosition}
                isValidNewOption={() => canCreateNewOption(inputValue)}
                inputValue={inputValue}
                isDisabled={disabled}
                isClearable
                onChange={onChange}
                onInputChange={(inputValue) => setInputValue(inputValue)}
                value={getCreatableOptionValue(value)}
                styles={{
                  menu: (provided: any) => ({ ...provided, zIndex: 9999, marginTop: '-6px' }),
                  control: (base: any) => ({
                    ...base,
                    height: 56,
                    width: size ? size : '200px',
                    boxShadow: 'none',
                    borderColor: error ? '#f44336' : '#9e9e9e',
                    marginTop: '0px',
                    '&:hover': {
                      borderColor: error ? '#f44336' : '#4b4b4b',
                    },
                  }),
                  placeholder: (base: any) => ({ ...base, color: error ? '#f44336' : '#9e9e9e' }),
                  dropdownIndicator: (base: any) => ({
                    ...base,
                    marginRight: endAdornment ? '30px' : '0px',
                  }),
                }}
              />
              {endAdornment && <div className={styles.endAdornment}>{endAdornment}</div>}
            </div>
            {error && <span className={styles.errorMessage}>{getErrorMessage(error)}</span>}
          </div>
        )
      }}
    />
  )
}

export default CreatableFormField
