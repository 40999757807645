export const invoicesEn = {
  //BEGIN Michael
  INVOICE: 'Invoice',
  INVOICES: 'Invoices',

  PENDING: {
    SINGLE: 'Pending',
    PLURAL: 'Pending',
  },

  APPROVED_FOR_FUNDING: {
    SINGLE: 'Approved for Funding',
    PLURAL: 'Approved for Funding',
  },

  FUNDED: {
    SINGLE: 'Funded',
    PLURAL: 'Funded',
  },

  PAID: {
    SINGLE: 'Paid',
    PLURAL: 'Paid',
  },

  INVOICE_NUMBER: 'Invoice Number',
  REFERENCE_NUMBER: 'Reference Number',
  BALANCE: {
    SINGLE: 'Balance',
    PLURAL: 'Balances',
  },
  TOTAL_INVOICES_PAYMENT: 'Total Invoices Payment',
  ESCROW: 'Escrow',
  INVOICE_BALANCE: 'Invoice Balance',
  REMAINING_INVOICE_BALANCE: 'Remaining Balance',
  ADVANCE_AMOUNT: 'Advance Amount',
  ESCROW_RESERVE_AMOUNT: 'Escrow Reserve Amount',
  CASH_RESERVE_AMOUNT: 'Cash Reserve Amount',
  PURCHASE_FEE_AMOUNT: 'Purchase Fee Amount',
  ADVANCE_AMOUNT_CANNOT_BE_NEGATIVE: 'Total advance amount cannot be negative',
  INVOICE_FEE: 'Invoice Fee',
  ACH_FEES: 'ACH Fee',
  WIRE_FEES: 'Wire Fee',
  CHECK_FEES: 'Check Fee',
  FUEL_CARD_FEES: 'Fuel Card Fee',
  SAME_DAY_ACH_FEES: 'Same Day ACH Fee',
  INVOICES_APPROVED_FOR_FUNDING_SUCCESSFULLY: 'Invoices approved for funding successfully!',
  REJECTED: 'Rejected',
  REJECT: 'Reject',
  REJECT_INVOICE: 'Reject Invoice',
  INVOICE_REJECTED_SUCCESSFULLY: 'Invoice/s rejected successfully!',
  INVOICE_PENDING_SUCCESSFULLY: 'Invoice/s moved to pending successfully!',
  REJECT_CONFIRMATION: 'Are you sure you want to reject these invoices?',
  PENDING_CONFIRMATION: 'Are you sure you want to move to pending these invoices?',
  INVOICES_WERE_SELECTED_FOR_THIS_ACTION: 'invoices were selected for this action',
  MOVE_TO_PENDING: 'Move to Pending',
  MOVE_TO_REJECTED: 'Move to Rejected',
  DEBTOR_CREDIT_LIMIT: 'Debtor Credit Limit',
  CLIENT_DEBTOR_CREDIT_LIMIT: 'Client-Debtor Credit Limit',
  DEBTOR_CREDIT: 'Debtor Credit',
  CLIENT_DEBTOR_CREDIT: 'Client-Debtor Credit',
  DEBTOR_CREDIT_INFO: 'Debtor Credit Info',
  CLIENT_DEBTOR_CREDIT_INFO: 'Client-Debtor Credit Info',
  DEBTOR_CREDIT_SCORE: 'Debtor Credit Score',
  AVERAGE_DAYS_TO_PAY: 'Average Days to Pay',
  INVOICES_RISK_PAST_DUE: 'Invoices at Risk of Past Due',
  INVOICES_PAST_DUE: 'Invoices Past Due',
  NO_LIMIT: 'No Limit',
  SPLIT_FUNDING: 'Split Funding',
  SPLIT_PAYMENT_AMOUNT_NOT_MATCH: 'The split payment amount does not match the invoice total advance.',
  DUPLICATE_FUNDING_TYPE: 'Duplicate payment option selected',
  DUPLICATE_PAYMENT_METHOD: 'Duplicate payment method selected',
  HELD_RESERVE: 'Hold (+) / Release (-)',
  ADDITIONAL_FEES: 'Additional Fees',
  RESERVE_ESCROW: 'Reserve/Escrow',
  EXCEED_PARENT_CREDIT_LIMIT: 'New advance amount will exceed the parent "{parentCompanyName}" credit limit',
  UPDATE_INVOICE_FUEL_ADVANCE_SUCCESSFULLY: 'Invoice Fuel Advance Updated Successfully',
  BROKER_HAS_NOT_PAYMENT_METHOD: 'The broker has no payment options, the invoice cannot be purchased.',
  BROKER_DEBTOR: 'Broker Debtor',
  INVOICE_DETAILS: 'Invoice Details',
  UPDATE_AMOUNT_DETAILS: 'Update Amount Details',
  UPDATE_AMOUNT_DETAILS_SUCCESSFULLY: 'Updated Amount Details Successfully',
  AMOUNT_DETAILS: 'Amount Details',
  //END Michael

  //BEGIN Fco
  INVOICE_DATE: 'Invoice Date',
  DOCUMENTS_ADDED_INVOICE: 'All documents were successfully added to the invoice',
  OPEN: 'Open',
  CLOSE: 'Close',
  CLOSED: 'Closed',
  //END Fco

  //BEGIN Fabian
  CREATE_INVOICE_GROUP_CONFIRMATION: 'Are you sure you want to approve these invoices for funding?',
  REFERENCE: 'Reference',
  DATE: 'Date',
  NOTES: 'Notes',
  INVOICE_CREATED_SUCCESSFULLY: 'Invoice created successfully',
  CREATE_INVOICE: 'Create Invoice',
  QUICK_CREATE: 'Quick Create',
  INVOICE_AMOUNT: 'Invoice Amount',
  CLIENT_LIMIT: 'Client Limit',
  DEBTOR_LIMIT: 'Debtor Limit',
  CLIENT_DEBTOR_LIMIT: 'Client-Debtor Limit',
  CLIENT_DEBTOR: 'Client-Debtor',
  COMPANY_HAS_CREDIT_LIMIT_APPROVED: 'The {company} "{companyName}" does not have an approved credit limit.',
  CREDIT_LIMIT_REACHED: 'The credit limit has been reached ',
  ESCROW_RESERVE: 'Escrow Reserve',
  TOTAL_ADVANCE: 'Total Advance',
  CREATE_INVOICE_GROUP: 'Approve for Funding',
  CREATE_INVOICE_GROUP_SUCCESSFULLY: 'Invoices approved for funding',
  SUMMARY: 'Summary',
  APPROVE_FOR_FUNDING: 'Approve for Funding',
  CASH_RESERVE: 'Cash Reserve',
  FEES: 'Fees',
  NET_ADVANCE: 'Net Advance',
  RESERVE_RELEASE: 'Reserve Release',
  FUNDING_INSTRUCTIONS: 'Funding Instructions',
  NOT_PAYMENT_METHOD: 'The client has no payment options, the invoice cannot be purchased.',
  IGNORE_PAYMENT_FEE: 'Ignore payment fee',
  PROGRESS_TOOLTIP: 'Current open AR / New approved amount / Credit limit',
  CALENDAR_DAYS: 'Calendar Days',
  BUSINESS_DAYS: 'Business Days',
  MONDAY_FRIDAY: 'Monday-Friday',
  TOOLTIP_MESSAGE_FEE: 'These fees are for reference only and will be applied on funding.',
  CURRENT_OPEN_AR: 'Current Open AR',
  NEW_APPROVED_AMOUNT: 'New Approved Amount',
  CREDIT_LIMIT: 'Credit Limit',
  VERIFIED: 'Verified',
  NOT_VERIFIED: 'Not Verified',
  DENIED: 'Denied',
  INFORMATION: 'Information',
  VERIFICATION: 'Verification',
  VERIFICATION_LOGS: 'Verification Logs',
  VERIFICATION_STATUS: 'Verification Status',
  VERIFY: 'Verify',
  INVOICES_VERIFY_SUCCESSFULLY: 'Invoices verified successfully',
  FEE_AMOUNT: 'Fee Amount',
  FUNDED_DATE: 'Funded Date',
  CLOSED_DATE: 'Closed Date',
  CALL_BACK_DATE: 'Call Back Date',
  WILL_PAY_DATE: 'Will Pay Date',
  DAYS_AGED: 'Days Aged',
  DAYS_OUTSTANDING: 'Days Outstanding',
  PAID_ON: 'Paid On',
  PAYMENT_STATUS: 'Payment Status',
  PAYMENT_STATUS_INFO:
    'This can be OPEN or CLOSED. Closed means it has been paid off, in any other case it will be open.',
  INVOICE_STATUS: 'Invoice Status',
  PAYMENT_TRANSACTION_NUMBER: 'Payment Transaction Number',
  MORE: 'More',
  LESS: 'Less',
  APPROVED: 'Approved',
  TERM_INFO_CREATE_BLADE: 'The term to be used for the invoice is: ',
  INVOICE_FORCED_TERM_MESSAGE: 'This is a custom forced term for the invoice.',
  INVOICE_TERM_EXPLANATION_FORCED: 'The term used for invoice is derived from the custom forced term.',
  INVOICE_TERM_EXPLANATION_CLIENT_DEBTOR:
    'The term used for the invoice is derived from the client-debtor relationship term or client term.',
  INVOICE_TERM_PRIORITY: 'The term used for the invoice has the following priority:',
  CUSTOM_FORCED_TERM: 'Custom forced term',
  CLIENT_DEBTOR_RELATIONSHIP_TERM: 'Client debtor relationship term',
  CLIENT_TERM: 'Client term',
  OVER_ADVANCE: 'Over Advance',
  OVER_ADVANCE_TYPE: 'Over Advance Type',
  UPDATE_OVER_ADVANCES_SUCCESSFULLY: 'Over Advances updated successfully',
  OOS: 'OOS',
  RESERVE_RELEASE_AMOUNT: 'Reserve Release Amount',
  TOTAL_NET_AMOUNT: 'Total Net Amount',
  EXPORT_INVOICES_SUCCESSFULLY: 'Invoices exported successfully',
  ARE_YOU_SURE_EXPORT_INVOICES: 'Are you sure you want to export the invoices?',
  EXPORT_INVOICES: 'Export Invoices',
  RESEND_BILLING_MESSAGE: 'You are about to resend the billing paperwork.',
  RESEND_BILLING_DESCRIPTION: 'If you continue, the invoices and supporting documents will be resent to the debtor.',
  REGENERATE_INVOICE: 'Regenerate Invoice',
  INVOICE_REEMERGE_MESSAGE:
    'Invoice page, invoice documents and NOA will reemerge, the old invoice will be moved to Inactive.',
  CONFIRM_REBILL: 'Confirm Rebill',
  REBILL_INVOICE_SUCCESSFULLY: 'Invoice rebilled successfully',
  REBILL: 'Rebill',
  //END Fabian

  //BEGIN Patricio
  AP_BANK_ACCOUNT: 'AP Bank Account',
  AR_BANK_ACCOUNT: 'AR Bank Account',
  UPDATE_INVOICE_DETAILS_SUCCESSFULLY: 'Updated Invoice Details Successfully',
  UPDATE_INVOICE_NOTES_SUCCESSFULLY: 'Updated Invoice Notes Successfully',
  CREATED_ON: 'Created On',
  NUMBER_OF_INVOICES: 'Number of Invoices',
  DELETE_INVOICE: 'Delete Invoice',
  DELETE_INVOICE_CONFIRMATION: 'Are you sure you want to delete the selected invoices?',
  DELETION_OF_ALL_INVOICES_NOT_SUPPORTED: 'Deletion of all invoices across all pages is not supported',
  DELETE_INVOICE_SUCCESSFULLY: 'Invoice/s deleted successfully',
  DELETED: 'Deleted',
  METHOD: 'Method',
  AUTOGENERATED_DOCUMENT_MSG: 'This document was autogenerated by the system.',
  //END Patricio

  //BEGIN Esteban
  VERIFY_STATUS_DENIED: 'Denied',
  VERIFY_STATUS_NOT_VERIFIED: 'Not verified',
  VERIFY_STATUS_PENDING: 'Pending',
  VERIFY_STATUS_VERIFIED: 'Verified',
  ERROR_REPORT_CONFIRMATION:
    'There were errors processing the CSV file, no new invoices were created. To see the error report, download the attached file.',
  RESERVES_FEE_NOTES: 'Hold / Release Notes',
  ADDITIONAL_FEE_NOTES: 'Additional Fee Notes',
  NON_FACTORED_INVOICE: 'Nonfactored Invoice',
  NF: 'NF',
  //END Esteban
  UPDATE_INVOICES: 'Update Invoices',
  UPDATE_INVOICE: 'Update Invoice',
  MULTIPLE_INVOICES_UPDATE_WARNING:
    'Once you submit this form, <strong>all associated invoices</strong> will be <strong>updated</strong> with the information you have provided. Please ensure all details are correct before proceeding.',
  COMPANY_DETAILS: '{0} Company Details',
  INVOICE_CONDITIONS: 'Invoice Conditions',

  OUTDATED: 'Outdated',
}

export const invoicesEs: typeof invoicesEn = {
  //BEGIN Michael
  INVOICE: 'Factura',
  INVOICES: 'Facturas',

  PENDING: {
    SINGLE: 'Pendiente',
    PLURAL: 'Pendientes',
  },
  APPROVED_FOR_FUNDING: {
    SINGLE: 'Aprobada para Financiamiento',
    PLURAL: 'Aprobadas para Financiamiento',
  },
  FUNDED: {
    SINGLE: 'Financiada',
    PLURAL: 'Financiadas',
  },
  PAID: {
    SINGLE: 'Pagada',
    PLURAL: 'Pagadas',
  },

  INVOICE_NUMBER: 'Número de factura',
  REFERENCE_NUMBER: 'Número de referencia',
  BALANCE: {
    SINGLE: 'Balance',
    PLURAL: 'Balances',
  },
  TOTAL_INVOICES_PAYMENT: 'Total de pagos de facturas',
  ESCROW: 'Fideicomiso',
  INVOICE_BALANCE: 'Balance de la factura',
  REMAINING_INVOICE_BALANCE: 'Balance restante',
  ADVANCE_AMOUNT: 'Monto del anticipo',
  ESCROW_RESERVE_AMOUNT: 'Monto de la reserva de garantía',
  CASH_RESERVE_AMOUNT: 'Monto de la reserva de efectivo',
  PURCHASE_FEE_AMOUNT: 'Monto de la tarifa de compra',
  ADVANCE_AMOUNT_CANNOT_BE_NEGATIVE: 'El monto del anticipo no puede ser negativo',
  INVOICE_FEE: 'Tasa de la factura',
  ACH_FEES: 'Tasa ACH',
  WIRE_FEES: 'Tasa de transferencia',
  CHECK_FEES: 'Tasa de cheque',
  FUEL_CARD_FEES: 'Tasa de tarjeta de combustible',
  SAME_DAY_ACH_FEES: 'Tasa ACH del mismo día',
  INVOICES_APPROVED_FOR_FUNDING_SUCCESSFULLY: '¡Facturas aprobadas para financiamiento exitosamente!',
  REJECTED: 'Rechazado',
  REJECT: 'Rechazar',
  REJECT_INVOICE: 'Rechazar factura',
  INVOICE_REJECTED_SUCCESSFULLY: '¡Factura/s rechazada/s exitosamente!',
  INVOICE_PENDING_SUCCESSFULLY: '¡Factura/s movida/s a pendiente exitosamente!',
  REJECT_CONFIRMATION: '¿Está seguro de que desea rechazar las facturas seleccionadas?',
  PENDING_CONFIRMATION: '¿Está seguro de que desea mover a pendiente las facturas seleccionadas?',
  INVOICES_WERE_SELECTED_FOR_THIS_ACTION: 'facturas fueron seleccionadas para esta acción',
  MOVE_TO_PENDING: 'Mover a Pendiente',
  MOVE_TO_REJECTED: 'Mover a Rechazado',
  DEBTOR_CREDIT_LIMIT: 'Límite de crédito del deudor',
  CLIENT_DEBTOR_CREDIT_LIMIT: 'Límite de crédito del cliente-deudor',
  DEBTOR_CREDIT: 'Crédito del deudor',
  CLIENT_DEBTOR_CREDIT: 'Crédito del cliente-deudor',
  DEBTOR_CREDIT_INFO: 'Información de crédito del deudor',
  CLIENT_DEBTOR_CREDIT_INFO: 'Información de crédito del cliente-deudor',
  DEBTOR_CREDIT_SCORE: 'Puntaje de crédito del deudor',
  AVERAGE_DAYS_TO_PAY: 'Días promedio para pagar',
  INVOICES_RISK_PAST_DUE: 'Facturas en riesgo de vencimiento',
  INVOICES_PAST_DUE: 'Facturas vencidas',
  NO_LIMIT: 'Sin límite',
  SPLIT_FUNDING: 'Financiamiento dividido',
  SPLIT_PAYMENT_AMOUNT_NOT_MATCH: 'El monto del pago dividido no coincide con el anticipo total de la factura.',
  DUPLICATE_FUNDING_TYPE: 'Tipo de financiamiento duplicado',
  DUPLICATE_PAYMENT_METHOD: 'Método de pago duplicado',
  HELD_RESERVE: 'mantener (+) / liberar (-)',
  ADDITIONAL_FEES: 'Tarifas adicionales',
  RESERVE_ESCROW: 'Reserva/Fideicomiso',
  EXCEED_PARENT_CREDIT_LIMIT:
    'El nuevo monto del anticipo excederá el límite de crédito del padre "{parentCompanyName}"',
  UPDATE_INVOICE_FUEL_ADVANCE_SUCCESSFULLY: 'Anticipo de combustible de factura actualizado con éxito',
  BROKER_HAS_NOT_PAYMENT_METHOD: 'El broker no tiene opciones de pago, la factura no puede ser comprada.',
  BROKER_DEBTOR: 'Deudor del broker',
  INVOICE_DETAILS: 'Detalles de la factura',
  UPDATE_AMOUNT_DETAILS: 'Actualizar detalles del monto',
  UPDATE_AMOUNT_DETAILS_SUCCESSFULLY: 'Detalles del monto actualizados con éxito',
  AMOUNT_DETAILS: 'Detalles del monto',
  //END Michael

  //BEGIN Fco
  INVOICE_DATE: 'Fecha de la factura',
  DOCUMENTS_ADDED_INVOICE: 'Todos los documentos han sido añadidos a la Factura.',
  OPEN: 'Abiertas',
  CLOSE: 'Cerrar',
  CLOSED: 'Cerradas',
  //END Fco

  //BEGIN Fabian
  CREATE_INVOICE_GROUP_CONFIRMATION:
    '¿Está seguro de que desea añadir las facturas seleccionadas al grupo de facturas?',
  REFERENCE: 'Referencia',
  DATE: 'Fecha',
  NOTES: 'Notas',
  INVOICE_CREATED_SUCCESSFULLY: 'Factura creada exitosamente',
  CREATE_INVOICE: 'Crear Factura',
  QUICK_CREATE: 'Crear Rápido',
  INVOICE_AMOUNT: 'Importe de la factura',
  COMPANY_HAS_CREDIT_LIMIT_APPROVED: 'El {company} {companyName} no tiene un límite de crédito aprobado.',
  CLIENT_LIMIT: 'Límite del cliente',
  DEBTOR_LIMIT: 'Límite del deudor',
  CLIENT_DEBTOR_LIMIT: 'Límite del cliente-deudor',
  CLIENT_DEBTOR: 'Cliente-Deudor',
  CREDIT_LIMIT_REACHED: 'Se ha alcanzado el límite de crédito',
  ESCROW_RESERVE: 'Reserva de garantía',
  TOTAL_ADVANCE: 'Total de anticipos',
  CREATE_INVOICE_GROUP: 'Crear Grupo de Facturas',
  CREATE_INVOICE_GROUP_SUCCESSFULLY: '¡Grupo de Facturas creado exitosamente!',
  SUMMARY: 'Resumen',
  APPROVE_FOR_FUNDING: 'Aprobar para financiamiento',
  CASH_RESERVE: 'Reserva de efectivo',
  FEES: 'Tarifas',
  NET_ADVANCE: 'Anticipo neto',
  RESERVE_RELEASE: 'Liberación de reserva',
  FUNDING_INSTRUCTIONS: 'Instrucciones de financiamiento',
  NOT_PAYMENT_METHOD: 'El cliente no tiene opciones de pago, la factura no puede ser comprada.',
  IGNORE_PAYMENT_FEE: 'Ignorar tarifa de pago',
  PROGRESS_TOOLTIP: 'Cuenta por cobrar actual / Nuevo monto aprobado / Límite de crédito',
  CALENDAR_DAYS: 'Días calendario',
  BUSINESS_DAYS: 'Días hábiles',
  MONDAY_FRIDAY: 'Lunes-Viernes',
  TOOLTIP_MESSAGE_FEE: 'Estas tarifas son solo de referencia y se aplicarán al financiamiento.',
  CURRENT_OPEN_AR: 'Cuenta por cobrar actual',
  NEW_APPROVED_AMOUNT: 'Nuevo monto aprobado',
  CREDIT_LIMIT: 'Límite de crédito',
  VERIFIED: 'Verificado',
  NOT_VERIFIED: 'No verificado',
  DENIED: 'Denegado',
  INFORMATION: 'Información',
  VERIFICATION: 'Verificación',
  VERIFICATION_LOGS: 'Registros de verificación',
  VERIFICATION_STATUS: 'Estado de verificación',
  VERIFY: 'Verificar',
  INVOICES_VERIFY_SUCCESSFULLY: 'Facturas verificadas exitosamente',
  FEE_AMOUNT: 'Monto de la tarifa',
  FUNDED_DATE: 'Fecha de financiamiento',
  CLOSED_DATE: 'Fecha de cierre',
  CALL_BACK_DATE: 'Fecha de llamada',
  WILL_PAY_DATE: 'Fecha de pago',
  DAYS_AGED: 'Días de antigüedad',
  DAYS_OUTSTANDING: 'Días pendientes',
  PAID_ON: 'Pagado el',
  PAYMENT_STATUS: 'Estado de pago',
  PAYMENT_STATUS_INFO:
    'Esto puede ser ABIERTO o CERRADO. Cerrado significa que ha sido pagado, en cualquier otro caso estará abierto.',
  INVOICE_STATUS: 'Estado de la factura',
  PAYMENT_TRANSACTION_NUMBER: 'Número de transacción de pago',
  MORE: 'Más',
  LESS: 'Menos',
  APPROVED: 'Aprobado',
  TERM_INFO_CREATE_BLADE: 'El término a utilizar para la factura es: ',
  INVOICE_FORCED_TERM_MESSAGE: 'Este es un término forzado personalizado para la factura.',
  INVOICE_TERM_EXPLANATION_FORCED: 'El término utilizado para la factura se deriva del término personalizado.',
  INVOICE_TERM_EXPLANATION_CLIENT_DEBTOR:
    'El término utilizado para la factura se deriva del término de la relación cliente-deudor o del término del cliente.',
  INVOICE_TERM_PRIORITY: 'El término utilizado para la factura tiene la siguiente prioridad:',
  CUSTOM_FORCED_TERM: 'Término forzado personalizado',
  CLIENT_DEBTOR_RELATIONSHIP_TERM: 'Término de la relación cliente-deudor',
  CLIENT_TERM: 'Término del cliente',
  OVER_ADVANCE: 'Sobre anticipos',
  OVER_ADVANCE_TYPE: 'Tipo de sobre anticipos',
  UPDATE_OVER_ADVANCES_SUCCESSFULLY: 'Sobre anticipos actualizados exitosamente',
  OOS: 'OOS',
  RESERVE_RELEASE_AMOUNT: 'Monto de liberación de reserva',
  TOTAL_NET_AMOUNT: 'Monto neto total',
  EXPORT_INVOICES_SUCCESSFULLY: 'Facturas exportadas exitosamente',
  ARE_YOU_SURE_EXPORT_INVOICES: '¿Está seguro de que desea exportar las facturas?',
  EXPORT_INVOICES: 'Exportar Facturas',
  RESEND_BILLING_MESSAGE: 'Estás a punto de reenviar la documentación de facturación.',
  RESEND_BILLING_DESCRIPTION: 'Si continúas, las facturas y los documentos de soporte se reenviarán al deudor.',
  REGENERATE_INVOICE: 'Regenerar Factura',
  INVOICE_REEMERGE_MESSAGE:
    'La página de la factura, los documentos de la factura y NOA volverán a aparecer, la antigua factura se moverá a Inactiva.',
  CONFIRM_REBILL: 'Confirmar Rebill',
  REBILL_INVOICE_SUCCESSFULLY: 'Factura reenviada exitosamente',
  REBILL: 'Rebill',
  //END Fabian

  //BEGIN Patricio
  AP_BANK_ACCOUNT: 'Cuenta Bancaria AP',
  AR_BANK_ACCOUNT: 'Cuenta Bancaria AR',
  UPDATE_INVOICE_DETAILS_SUCCESSFULLY: 'Los detalles del Invoice se actualizaron exitosamente',
  UPDATE_INVOICE_NOTES_SUCCESSFULLY: 'Las notas del Invoice se actualizaron exitosamente',
  CREATED_ON: 'Creado el',
  NUMBER_OF_INVOICES: 'Número de Facturas',
  DELETE_INVOICE: 'Eliminar Factura',
  DELETE_INVOICE_CONFIRMATION: '¿Está seguro de que desea eliminar las facturas seleccionadas?',
  DELETION_OF_ALL_INVOICES_NOT_SUPPORTED: 'No se admite la eliminación de todas las facturas en todas las páginas',
  DELETE_INVOICE_SUCCESSFULLY: '¡Factura/s eliminada/s exitosamente!',
  DELETED: 'Eliminado',
  METHOD: 'Método',
  AUTOGENERATED_DOCUMENT_MSG: 'Este documento fue generado automáticamente por el sistema.',
  //END Patricio

  //BEGIN Esteban
  VERIFY_STATUS_DENIED: 'Denegado',
  VERIFY_STATUS_NOT_VERIFIED: 'No verificado',
  VERIFY_STATUS_PENDING: 'Pendiente',
  VERIFY_STATUS_VERIFIED: 'Verificado',
  ERROR_REPORT_CONFIRMATION:
    'Hubieron errores al procesar el archivo CSV, no se crearon facturas nuevas. Parar ver el reporte de errores, descargue el archivo adjunto',
  RESERVES_FEE_NOTES: 'Notas de tarifas de reservas',
  ADDITIONAL_FEE_NOTES: 'Notas de tarifas adicionales',
  NON_FACTORED_INVOICE: 'Factura no factorizada',
  NF: 'NF',
  //END Esteban
  UPDATE_INVOICES: 'Actualizar Facturas',
  UPDATE_INVOICE: 'Actualizar Factura',
  MULTIPLE_INVOICES_UPDATE_WARNING:
    'Una vez que envíe este formulario, **todas las facturas asociadas** se actualizarán con la información que ha proporcionado. Asegúrese de que todos los detalles sean correctos antes de continuar.',
  COMPANY_DETAILS: 'Detalles de {0}',
  INVOICE_CONDITIONS: 'Condición de Facturas',

  OUTDATED: 'Desactualizado',
}
