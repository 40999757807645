import { PermissionInfo } from 'types/rolePermission'
import useUserSession from './useUserSession'
import { useMemo } from 'react'

const codeCanReadUsers = 'can-read-users'
const codeCanWriteUsers = 'can-write-users'
const codeCanReadTerms = 'can-read-terms'
const codeCanWriteTerms = 'can-write-terms'
const codeCanReadClients = 'can-read-clients'
const codeCanWriteClients = 'can-write-clients'
const codeCanReadDebtors = 'can-read-debtors'
const codeCanWriteDebtors = 'can-write-debtors'
const codeCanReadClientFundingInstructionList = 'can-read-client-funding-instruction-list'
const codeCanWriteClientFundingInstructionDetails = 'can-write-client-funding-instruction-details'
const codeCanReadClientFundingInstructionDetails = 'can-read-client-funding-instruction-details'
const codeCanPurchaseInvoices = 'can-purchase-invoices'
const codeCanCreateOrEditInvoices = 'can-write-invoices'
const codeCanCreateOrEditDisbursements = 'can-write-disbursements'
const codeCanApproveInvoices = 'can-approve-invoices'
const codeCanReadDisbursements = 'can-read-disbursements'
const codeCanReadInvoices = 'can-read-invoices'
const codeCanVerifyInvoices = 'can-verify-invoices'
const codeCanReadPayments = 'can-read-payments'
const codeCanWritePayments = 'can-write-payments'
const codeCanApplyPayments = 'can-apply-payments'
const codeCanReadManualLedgers = 'can-read-manual-ledgers'
const codeCanWriteManualLedgers = 'can-write-manual-ledgers'
const codeCanReadLedgers = 'can-read-ledgers'
const codeCanAccessAdminLevelReporting = 'can-access-admin-level-reporting'
const codeCanAccessNormalLevelReporting = 'can-access-normal-level-reporting'
const codeCanWriteDocuments = 'can-write-documents'
const codeCanAdjustOtherUserSettings = 'can-adjust-other-user-settings'
const codeCanAccessAdminLevelSettings = 'can-access-admin-level-setting'
const codeCanReadDocuments = 'can-read-documents'
const codeCanReadAutocomplete = 'can-read-autocomplete'
const codeCanAccessFactorAdminSettings = 'can-access-factor-admin-settings'
const codeCanReadFuelAdvances = 'can-read-fuel-advances'
const codeCanWriteFuelAdvances = 'can-write-fuel-advances'
const codeCanWriteBrokers = 'can-write-brokers'
const codeCanReadBrokers = 'can-read-brokers'
const codeCanWriteVendors = 'can-write-vendors'
const codeCanReadVendors = 'can-read-vendors'
const codeCanReadFactorAccounts = 'can-read-factor-accounts'
const codeCanWriteFactorAccounts = 'can-write-factor-accounts'
const codeCanSetForcedInvoiceTerm = 'can-set-forced-invoice-term'
const codeCanReadTankPayment = 'can-read-tank-payments'
const codeCanWriteTankPayment = 'can-write-tank-payments'
const codeCanWriteOpenInvoices = 'can-write-open-invoices'
const codeCanWriteLedgerName = 'can-write-ledger-name'
const codeCanReadLedgerName = 'can-read-ledger-name'
const codeCanWriteVerificationMethod = 'can-write-verification-method'
const codeCanReadVerificationMethod = 'can-read-verification-method'
const codeCanWriteOutOfSystemInvoice = 'can-write-out-of-system-invoice'
const codeCanReadOutOfSystemInvoice = 'can-read-out-of-system-invoice'
const codeCanReadEmailLogs = 'can-read-email-logs'
const codeCanReadReserveReleases = 'can-read-reserve-releases'
const codeCanWriteReserveReleases = 'can-write-reserve-releases'
const codeCanAccessClientAdminSettings = 'can-access-client-admin-settings'
const codeCanAccessAdminPaymentAccounts = 'can-access-admin-payment-accounts'
const codeCanWriteLabels = 'can-write-labels'
const codeCanModifyFactorDefaultValues = 'can-modify-factor-default-values'
const codeAccessDocuments = 'brightbolt-access'
const codeCanRebillInvoices = 'can-rebill-invoices'
const codeCanWriteNoaSettings = 'can-edit-noa-settings'
const codeCanWritePaymentBatches = 'can-write-payment-batch'

export function hasPermission(permissionsToCheck: string[], userPermissions: PermissionInfo[] = []) {
  const pList = userPermissions.map((p) => p.code)
  return permissionsToCheck.some((permission) => pList.includes(permission))
}

const usePermissions = () => {
  const { permissions } = useUserSession()

  const canReadUsers = useMemo(() => hasPermission([codeCanReadUsers, codeCanWriteUsers], permissions), [permissions])
  const canWriteUsers = useMemo(() => hasPermission([codeCanWriteUsers], permissions), [permissions])
  const canReadTerms = useMemo(() => hasPermission([codeCanReadTerms, codeCanWriteTerms], permissions), [permissions])
  const canWriteTerms = useMemo(() => hasPermission([codeCanWriteTerms], permissions), [permissions])
  const canReadClients = useMemo(
    () => hasPermission([codeCanReadClients, codeCanWriteClients], permissions),
    [permissions]
  )
  const canWriteClients = useMemo(() => hasPermission([codeCanWriteClients], permissions), [permissions])
  const canReadDebtors = useMemo(
    () => hasPermission([codeCanReadDebtors, codeCanWriteDebtors], permissions),
    [permissions]
  )
  const canWriteDebtors = useMemo(() => hasPermission([codeCanWriteDebtors], permissions), [permissions])
  const canReadClientFundingInstructionList = useMemo(
    () =>
      hasPermission(
        [
          codeCanReadClientFundingInstructionList,
          codeCanReadClientFundingInstructionDetails,
          codeCanWriteClientFundingInstructionDetails,
        ],
        permissions
      ),
    [permissions]
  )
  const canReadClientFundingInstructionDetails = useMemo(
    () =>
      hasPermission(
        [codeCanReadClientFundingInstructionDetails, codeCanWriteClientFundingInstructionDetails],
        permissions
      ),
    [permissions]
  )
  const canWriteClientFundingInstructionDetails = useMemo(
    () => hasPermission([codeCanWriteClientFundingInstructionDetails], permissions),
    [permissions]
  )
  const canReadInvoices = useMemo(
    () =>
      hasPermission(
        [codeCanReadInvoices, codeCanCreateOrEditInvoices, codeCanVerifyInvoices, codeCanApproveInvoices],
        permissions
      ),
    [permissions]
  )
  const canWriteInvoices = useMemo(() => hasPermission([codeCanCreateOrEditInvoices], permissions), [permissions])
  const canPurchaseInvoices = useMemo(() => hasPermission([codeCanPurchaseInvoices], permissions), [permissions])
  const canApproveInvoices = useMemo(() => hasPermission([codeCanApproveInvoices], permissions), [permissions])
  const canVerifyInvoices = useMemo(() => hasPermission([codeCanVerifyInvoices], permissions), [permissions])
  const canReadDisbursements = useMemo(
    () =>
      hasPermission([codeCanReadDisbursements, codeCanCreateOrEditDisbursements, codeCanPurchaseInvoices], permissions),
    [permissions]
  )
  const canWriteDisbursements = useMemo(
    () => hasPermission([codeCanCreateOrEditDisbursements], permissions),
    [permissions]
  )
  const canReadPayments = useMemo(
    () => hasPermission([codeCanReadPayments, codeCanWritePayments, codeCanApplyPayments], permissions),
    [permissions]
  )
  const canApplyPayments = useMemo(() => hasPermission([codeCanApplyPayments], permissions), [permissions])
  const canWritePayments = useMemo(() => hasPermission([codeCanWritePayments], permissions), [permissions])

  const canReadManualLedgers = useMemo(
    () => hasPermission([codeCanReadManualLedgers, codeCanWriteManualLedgers], permissions),
    [permissions]
  )

  const canWriteManualLedgers = useMemo(() => hasPermission([codeCanWriteManualLedgers], permissions), [permissions])

  const canReadLedgers = useMemo(() => hasPermission([codeCanReadLedgers], permissions), [permissions])

  const canAccessAdminLevelReporting = useMemo(
    () => hasPermission([codeCanAccessAdminLevelReporting], permissions),
    [permissions]
  )

  const canAccessNormalLevelReporting = useMemo(
    () => hasPermission([codeCanAccessNormalLevelReporting], permissions),
    [permissions]
  )

  const canReadDocuments = useMemo(
    () => hasPermission([codeCanReadDocuments, codeCanWriteDocuments], permissions),
    [permissions]
  )

  const canWriteDocuments = useMemo(() => hasPermission([codeCanWriteDocuments], permissions), [permissions])

  const canAdjustOtherUserSettings = useMemo(
    () => hasPermission([codeCanAdjustOtherUserSettings], permissions),
    [permissions]
  )

  const canAccessAdminLevelSettings = useMemo(
    () => hasPermission([codeCanAccessAdminLevelSettings], permissions),
    [permissions]
  )

  const canReadAutocomplete = useMemo(() => hasPermission([codeCanReadAutocomplete], permissions), [permissions])
  const canAccessFactorAdminSettings = useMemo(
    () => hasPermission([codeCanAccessFactorAdminSettings], permissions),
    [permissions]
  )

  const canReadFuelAdvances = useMemo(
    () => hasPermission([codeCanReadFuelAdvances, codeCanWriteFuelAdvances], permissions),
    [permissions]
  )

  const canWriteFuelAdvances = useMemo(() => hasPermission([codeCanWriteFuelAdvances], permissions), [permissions])

  const canReadBrokers = useMemo(
    () => hasPermission([codeCanReadBrokers, codeCanWriteBrokers], permissions),
    [permissions]
  )

  const canWriteBrokers = useMemo(() => hasPermission([codeCanWriteBrokers], permissions), [permissions])

  const canReadVendors = useMemo(
    () => hasPermission([codeCanWriteVendors, codeCanReadVendors], permissions),
    [permissions]
  )
  const canWriteVendors = useMemo(() => hasPermission([codeCanWriteVendors], permissions), [permissions])

  const canReadFactorAccounts = useMemo(
    () => hasPermission([codeCanReadFactorAccounts, codeCanWriteFactorAccounts], permissions),
    [permissions]
  )

  const canWriteFactorAccounts = useMemo(() => hasPermission([codeCanWriteFactorAccounts], permissions), [permissions])

  const canSetForcedInvoiceTerm = useMemo(
    () => hasPermission([codeCanSetForcedInvoiceTerm], permissions),
    [permissions]
  )

  const canReadTankPayments = useMemo(
    () => hasPermission([codeCanReadTankPayment, codeCanWriteTankPayment], permissions),
    [permissions]
  )

  const canWriteTankPayments = useMemo(() => hasPermission([codeCanWriteTankPayment], permissions), [permissions])

  const canWriteOpenInvoices = useMemo(() => hasPermission([codeCanWriteOpenInvoices], permissions), [permissions])

  const canWriteLedgerName = useMemo(() => hasPermission([codeCanWriteLedgerName], permissions), [permissions])

  const canReadLedgerName = useMemo(
    () => hasPermission([codeCanReadLedgerName, codeCanWriteLedgerName], permissions),
    [permissions]
  )

  const canWriteVerificationMethod = useMemo(
    () => hasPermission([codeCanWriteVerificationMethod], permissions),
    [permissions]
  )
  const canReadVerificationMethod = useMemo(
    () => hasPermission([codeCanReadVerificationMethod, codeCanWriteVerificationMethod], permissions),
    [permissions]
  )

  const canWriteOutOfSystemInvoice = useMemo(
    () => hasPermission([codeCanWriteOutOfSystemInvoice], permissions),
    [permissions]
  )

  const canReadOutOfSystemInvoice = useMemo(
    () => hasPermission([codeCanWriteOutOfSystemInvoice, codeCanReadOutOfSystemInvoice], permissions),
    [permissions]
  )
  const canReadEmailLogs = useMemo(() => hasPermission([codeCanReadEmailLogs], permissions), [permissions])

  const canReadReserveReleases = useMemo(
    () => hasPermission([codeCanReadReserveReleases, codeCanWriteReserveReleases], permissions),
    [permissions]
  )

  const canWriteReserveReleases = useMemo(
    () => hasPermission([codeCanWriteReserveReleases], permissions),
    [permissions]
  )

  const canAccessClientAdminSettings = useMemo(
    () => hasPermission([codeCanAccessClientAdminSettings], permissions),
    [permissions]
  )

  const canAccessAdminPaymentAccounts = useMemo(
    () => hasPermission([codeCanAccessAdminPaymentAccounts], permissions),
    [permissions]
  )

  const canWriteLabels = useMemo(() => hasPermission([codeCanWriteLabels], permissions), [permissions])

  const canModifyFactorDefaultValues = useMemo(
    () => hasPermission([codeCanModifyFactorDefaultValues], permissions),
    [permissions]
  )

  const canAccessDocuments = useMemo(() => hasPermission([codeAccessDocuments], permissions), [permissions])

  const canRebillInvoices = useMemo(() => hasPermission([codeCanRebillInvoices], permissions), [permissions])

  const canWriteNoaSettings = useMemo(() => hasPermission([codeCanWriteNoaSettings], permissions), [permissions])

  const canWritePaymentBatches = useMemo(() => hasPermission([codeCanWritePaymentBatches], permissions), [permissions])

  return {
    canReadUsers,
    canWriteUsers,
    canReadTerms,
    canWriteTerms,
    canReadClients,
    canWriteClients,
    canReadDebtors,
    canWriteDebtors,
    canReadClientFundingInstructionList,
    canReadClientFundingInstructionDetails,
    canWriteClientFundingInstructionDetails,
    canReadInvoices,
    canWriteInvoices,
    canPurchaseInvoices,
    canApproveInvoices,
    canVerifyInvoices,
    canReadDisbursements,
    canWriteDisbursements,
    canReadPayments,
    canWritePayments,
    canApplyPayments,
    canReadManualLedgers,
    canWriteManualLedgers,
    canReadLedgers,
    canAccessAdminLevelReporting,
    canAccessNormalLevelReporting,
    canReadDocuments,
    canWriteDocuments,
    canAdjustOtherUserSettings,
    canAccessAdminLevelSettings,
    canReadAutocomplete,
    canAccessFactorAdminSettings,
    canReadFuelAdvances,
    canWriteFuelAdvances,
    canReadBrokers,
    canWriteBrokers,
    canWriteVendors,
    canReadVendors,
    canReadFactorAccounts,
    canWriteFactorAccounts,
    canSetForcedInvoiceTerm,
    canReadTankPayments,
    canWriteTankPayments,
    canWriteOpenInvoices,
    canWriteLedgerName,
    canReadLedgerName,
    canWriteVerificationMethod,
    canReadVerificationMethod,
    canWriteOutOfSystemInvoice,
    canReadOutOfSystemInvoice,
    canReadEmailLogs,
    canReadReserveReleases,
    canWriteReserveReleases,
    canAccessClientAdminSettings,
    canAccessAdminPaymentAccounts,
    canWriteLabels,
    canModifyFactorDefaultValues,
    canAccessDocuments,
    canRebillInvoices,
    canWriteNoaSettings,
    canWritePaymentBatches,
  }
}

export default usePermissions
