import request from 'tools/request'
import {
  UserListResponse,
  UpdateUserRequest,
  UserResponse,
  CreateUserRequest,
  UpdatePasswordRequest,
  UserExistsResponse,
} from '../types/users'
import { PAGINATION_LIMIT_VALUE_NUM, PAGINATION_NUM_VALUE_NUM } from '../constants/headers'
import { ListUserParams } from 'types/users'
import { getPaginationHeaders } from 'tools/headers'
import { BaseResponse } from '../types/common'

export const listUsers = async (
  params?: ListUserParams,
  pageNumber: number = PAGINATION_NUM_VALUE_NUM,
  pageLimit: number = PAGINATION_LIMIT_VALUE_NUM
) => {
  const data = await request
    .get<UserListResponse>('users', {
      params: params,
      headers: getPaginationHeaders(pageNumber, pageLimit),
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data.users,
      }
    })

  return data
}

export const getUser = async (userId: string) => {
  const data = await request.get<UserResponse>(`users/${userId}`).then((res) => res.data.user)
  return data
}

export const getMyUser = async () => {
  const data = await request.get<UserResponse>('users/me').then((res) => res.data.user)
  return data
}

export const createUser = async (payload: CreateUserRequest) => {
  return await request.post<UserResponse>('users', payload)
}

export const updateUser = async (userId: string, payload: UpdateUserRequest) => {
  const data = await request.put<UserResponse>(`users/${userId}`, payload)
  return data
}

export const deleteUser = async (userId: string) => {
  const data = await request.delete<BaseResponse>(`users/${userId}`)
  return data
}

export const updatePassword = async (payload: UpdatePasswordRequest) => {
  const data = await request.put<UserResponse>('users/password', payload)
  return data
}

export const userExists = async (email: string) => {
  const data = await request
    .get<UserExistsResponse>(`users/exists?email=${encodeURIComponent(email)}`)
    .then((res) => res.data)
  return data
}

export const updateUserRole = async (userId: string, payload: UpdateUserRequest) => {
  const data = await request.put<UserResponse>(`users/${userId}/role`, payload)
  return data
}
