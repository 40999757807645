import React, { createContext, useContext, ReactNode } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getCountries } from 'api/countries'
import { qkBaseCountry } from 'constants/QueryKeys/countries'
import { CompanyInfo } from 'types/company'
import { CountryInfo, CountryStateInfo, StateInfo } from 'types/country'
import { isDefined } from 'tools/common'
import { DropDownOptions } from 'types/common'

interface CountriesContextProps {
  getCountryDropdownOptions: () => DropDownOptions[]
  getStateOptions: (countryCode?: string) => { label: string; value: string }[]
  getCountryStateInfo: (countryCode?: string, stateCode?: string) => CountryStateInfo | undefined
  getCountryInfo: (countryCode?: string) => CountryInfo | undefined
  getStateInfo: (countryCode?: string, stateCode?: string) => StateInfo | undefined
  isFieldRequired: (countryCode?: string) => boolean
  existsCountry: (countryCode?: string) => boolean
  existState: (stateCode?: string) => boolean
  getCountryForCreatable: (countryCode?: string) => { label: string; value: string } | undefined
}

const CountriesContext = createContext<CountriesContextProps | undefined>(undefined)

export const useCountriesContext = () => {
  const context = useContext(CountriesContext)
  if (!context) {
    throw new Error('useCountriesContext must be used within a CountriesProvider')
  }
  return context
}

export const CountriesProvider: React.FC<{ children: ReactNode; initialCompany?: CompanyInfo }> = ({ children }) => {
  const { data: countries } = useQuery({
    queryKey: qkBaseCountry,
    queryFn: getCountries,
  })

  const getCountryDropdownOptions = (): DropDownOptions[] => {
    let countriesOptions: DropDownOptions[] = []
    if (isDefined(countries)) {
      countriesOptions = countries?.map((country) => ({
        label: country.countryName,
        value: country.countryCode,
      }))
    }
    return countriesOptions
  }

  const getStateOptions = (countryCode?: string) => {
    const country = countries?.find((c) => c.countryCode === countryCode)
    return country
      ? country.states.map((state) => ({
          label: state.stateName,
          value: state.stateCode,
        }))
      : []
  }

  const getCountryStateInfo = (countryCode?: string, stateCode?: string) => {
    if (isDefined(countries)) {
      const country: CountryInfo | undefined = countries?.find((c) => c.countryCode === countryCode)
      if (isDefined(country)) {
        const state: StateInfo | undefined = country.states.find((s) => s.stateCode === stateCode)
        if (isDefined(state)) {
          const countryStateInfo: CountryStateInfo = {
            countryCode: country.countryCode,
            countryName: country.countryName,
            stateCode: state.stateCode,
            stateName: state.stateName,
            stateType: state.stateType,
          }
          return countryStateInfo
        }
      }
    }
  }

  function existsCountry(countryCode?: string) {
    if (isDefined(countryCode) && isDefined(countries)) {
      return countries?.some((c) => c.countryCode === countryCode)
    }
    return false
  }

  const getCountryInfo = (countryCode?: string) => {
    if (isDefined(countryCode) && !existsCountry(countryCode)) {
      return { countryName: countryCode, countryCode: countryCode, states: [] }
    }
    return countries?.find((c) => c.countryCode === countryCode)
  }

  function existState(stateCode?: string) {
    if (isDefined(stateCode) && isDefined(countries)) {
      return countries?.some((c) => c.states.some((s) => s.stateCode === stateCode))
    }
    return false
  }

  const getStateInfo = (countryCode?: string, stateCode?: string) => {
    let stateInfo: StateInfo | undefined
    if (!existsCountry(countryCode)) {
      if (existState(stateCode)) {
        stateInfo = countries
          ?.find((c) => c.states.some((s) => s.stateCode === stateCode))
          ?.states.find((s) => s.stateCode === stateCode)
      }
    } else {
      const country = getCountryInfo(countryCode)
      stateInfo = country?.states.find((s) => s.stateCode === stateCode)
    }

    return stateInfo
  }

  function getCountryForCreatable(countryCode?: string) {
    if (isDefined(countryCode)) {
      const country = countries?.find((c) => c.countryCode === countryCode)
      if (isDefined(country)) {
        return { label: country.countryName, value: country.countryCode }
      } else {
        return { label: countryCode, value: countryCode }
      }
    }
  }

  const isFieldRequired = (countryCode?: string): boolean => {
    let required = true
    if (!existsCountry(countryCode)) {
      required = false
    }
    return required
  }

  return (
    <CountriesContext.Provider
      value={{
        getCountryDropdownOptions,
        getStateOptions,
        getCountryStateInfo,
        getCountryInfo,
        getStateInfo,
        isFieldRequired,
        existsCountry,
        existState,
        getCountryForCreatable,
      }}
    >
      {children}
    </CountriesContext.Provider>
  )
}
